<script>
import InputField from '@/components/general/InputField'
import ConfirmationModal from '@/components/general/ConfirmationModal.vue'
import { maxLength } from 'vuelidate/lib/validators'
export default {
  components: { InputField, ConfirmationModal },
  name: 'EditDepartment',
  data () {
    return {
      formData: {
        name: '',
        isReady: false,
        modalOpen: true
      },
      departments: []
    }
  },
  validations: {
    formData: {
      name: {
        maxLength: maxLength(255)
      }
    }
  },
  created () {
    this.getDepartments()
  },
  methods: {
    getDepartments () {
      this.$store.dispatch('attemptGetDepartments')
        .then(({ data }) => {
          this.departments = data.departments.map((department) => (
            {
              id: department._id,
              name: department.name
            }
          ))
          this.departments.sort((a, b) => a.name.localeCompare(b.name))
          this.isReady = true
        })
    },
    saveDepartment () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const existDepartment = this.departments.filter(d => d.name === this.formData.name)
        if (!existDepartment.length) {
          this.$store.dispatch('attemptSaveDepartment', this.formData.name)
            .then(() => {
              this.$store.dispatch('attemptSetFeedbackMsg', {
                type: 'success',
                title: this.$t('settings.admincenter.departments.labels:add.success')
              })
              this.isReady = true
              this.$router.push({ name: 'business.admin.center.departments.active', params: { edit: true } })
            })
            .catch((err) => {
              if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.includes('departmentExists')) {
                  this.$store.dispatch('attemptSetFeedbackMsg', {
                    type: 'error',
                    title: this.$t('settings.admincenter.departments.labels:add.error')
                  })
                }
              }
            })
        } else {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.departments.labels:add.error')
          })
        }
      } else {
        console.log('error')
      }
    }
  }
}
</script>
<template>
  <confirmation-modal
    :show="true"
    :btnConfirmText="false"
    :title="$t('admin.center.departments.new')"
    :confirmText="$t('global:add')"
    :cancelText="$t('global:cancel')"
    @cancel="$router.go(-1)"
    @confirm="saveDepartment()"
    :disableConfirmBtn="!formData.name.length || !formData.name.trim().length"
  >
    <div class="edit-department--form">
      <p class="bold">{{ $t('admin.center.departments:department.name') }}</p>
      <input-field v-model="formData.name" outlined dense :validation="$v.formData.name"></input-field>
    </div>
  </confirmation-modal>
</template>
<style lang="scss">
.edit-department--container {
  padding-top: 130px;
  .edit-department--header {
    text-align: left;
    margin-bottom: 60px;
    .edit-department-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #212121;
    }
  }
  .edit-department--actions {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}
</style>
